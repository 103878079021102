<template>
  <div id="app">
    <router-view
      @updateUI="rewriteCSSValues"
    />
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        background: 'app/background',
        primaryColor: 'app/primaryColor',
        tertiaryColor: 'app/tertiaryColor',
        secondaryColor: 'app/secondaryColor',
      }),
    },
    /**
     *  Rewrites tailwind classes
     */
    created() {
      this.rewriteCSSValues()
    },
    mounted() {
      window.addEventListener('resize', this.resizeHandler)
      window.dispatchEvent(new Event('resize'))
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    methods: {
      resizeHandler() {
        this.$store.commit('app/setScreenDimensions', {
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        })
      },
      /**
       * Rewrites css variables and sets primary, secondary and
       * tertiary color.
       */
      rewriteCSSValues() {
        let id = 'css-rewrite'
        let created = false
        let cssSheet = document.querySelector(`#${id}`)
        if (!cssSheet) {
          cssSheet = document.createElement('style')
          cssSheet.id = id
          created = true
        }

        let background = this.tertiaryColor
        if (this.background) {
          switch (this.background.type) {
            case 'image':
              background = `url("${this.background.value}")`
              break
            case 'color':
              background = this.background.value
              break
          }
        }
        cssSheet.innerHTML = `
          :root {
            --primary: ${this.primaryColor};
            --secondary: ${this.secondaryColor};
            --tertiary: ${this.tertiaryColor};
            --background: ${background};
          }
        `
        if (created) {
          document.body.appendChild(cssSheet)
        }
      }
    },
    watch: {
      '$route': {
        deep: true,
        immediate: true,
        handler() {
          let customCSSItem = 'default'
          if (this.$route.params.slug) {
            customCSSItem = this.$helper.extractIdFromSlug(this.$route.params.slug)
          }
          this.$store.dispatch('config/loadCustomCSS', customCSSItem)
        }
      }
    },
    inject: [
      '$helper',
      '$api',
    ]
  }
</script>

<style lang="scss">
  @import "~@/assets/scss/app";
</style>
